import React from "react"
import "./got-questions.scss"
import Slider from "react-slick"
import { Link } from "gatsby"
import { Accordion, Card, Button } from "react-bootstrap"
import { gotQuestionsData, gotQuestionsFlourishingData } from "./helper"
import ArrowRight from "../../images/learnmore/arrowRight-light.svg"
import ArrowLeft from "../../images/learnmore/arrowLeft-light.svg"

const GotQuestions = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <img src={ArrowRight} className="faqArrow" />,
    prevArrow: <img src={ArrowLeft} className="faqArrow" />,
  }
  return (
    <div className="gotQuestWrapper" id="faqs">
      <div className="gotQuestContentWrapper">
        <h2 className="gotQuestHeading">Frequently Asked Questions</h2>
        <Slider {...settings}>
          <div>
            <Accordion defaultActiveKey="0">
              {gotQuestionsData?.map((acc, index) => (
                <div
                  className="gotQuestFaqItem"
                  key={`questions-${acc.heading}-${index}`}
                >
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={index.toString()}
                  >
                    <div className="gotQuestSubHeading">
                      <span>{acc.heading}</span>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index.toString()}>
                    <Card.Body className={"gotQuestAnswer"}>
                      <p
                        dangerouslySetInnerHTML={{ __html: acc.description }}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </div>
              ))}
            </Accordion>
            <div className="gotQuestionsCtaWrapper">
              <Link to="/contact" className="gotQuestionsCta">
                Contact Us
              </Link>
            </div>
          </div>

          <div>
            <Accordion defaultActiveKey="0">
              {gotQuestionsFlourishingData?.map((acc, index) => (
                <div
                  className="gotQuestFaqItem"
                  key={`questions-${acc.heading}-${index}`}
                >
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={index.toString()}
                  >
                    <div className="gotQuestSubHeading">
                      <span>{acc.heading}</span>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index.toString()}>
                    <Card.Body className="gotQuestAnswer">
                      <p
                        dangerouslySetInnerHTML={{ __html: acc.description }}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </div>
              ))}
            </Accordion>
            <div className="gotQuestionsCtaWrapper">
              <Link to="/contact" className="gotQuestionsCta">
                Contact Us
              </Link>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default GotQuestions
