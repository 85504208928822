const gotQuestionsData = [
  {
    heading: "What is SHAPE?",
    description:
      'SHAPE is the world\'s first purpose-built product to enhance employee flourishing. SHAPE precisely measures and reports the degree of struggling, striving, and flourishing among employees across  <a href="/science/#explorer-view"> 12 areas</a> critical to performance.',
  },
  {
    heading: "What's included in the SHAPE package?",
    description:
      'Please review the <a href="/pricing">pricing page</a> to get specific details on what\'s included in your SHAPE package.',
  },
  {
    heading: "How do I get started with SHAPE?",
    description:
      'Please fill out the form on <a href="/launch">this</a> page, and we\'ll be in touch rapidly. Alternatively, reach us at <a  href="mailto:sales@shapepowered.com" target="_blank">sales@shapepowered.com</a> for assistance.',
  },
  {
    heading: "What devices can SHAPE be used on?",
    description:
      "SHAPE is optimised for use on desktop, mobile phone, and tablets. Choose whatever is the most convenient option for you.",
  },
  {
    heading: "How do I continue my SHAPE journey?",
    description:
      'To renew SHAPE, please contact us at <a  href="mailto:sales@shapepowered.com" target="_blank">sales@shapepowered.com</a>',
  },
  {
    heading: "How do I cancel?",
    description:
      'For cancellations, please reach us at <a  href="mailto:help@shapepowered.com" target="_blank">help@shapepowered.com</a>',
  },
  {
    heading: "Is SHAPE available in my country?",
    description:
      "SHAPE is available worldwide, faster flourishing has no borders.",
  },
]

const gotQuestionsFlourishingData = [
  {
    heading: "What is Flourishing?",
    description:
      'Flourishing means people performing at their peak to live to their fullest potential. Learn more about the history of flourishing in <a href="#book-of-flourishing-home">The Book of Flourishing.</a>',
  },
  {
    heading: "How do I know flourishing is right for me?",
    description:
      "Flourishing goes hand in hand with productivity, engagement, resilience, commitment, adaptability and much more. All of these are critical to organisational performance and success. That's why Flourishing should be your number one priority.",
  },
  {
    heading:
      "Why should I focus on flourishing instead of other metrics like productivity or engagement?",
    description:
      "Flourishing is a super-metric. Flourishing takes care of productivity, engagement and all other essential metrics automatically. Not only that, it also improves metrics that you weren't even focussed on.",
  },
  {
    heading: "What does faster flourishing mean?",
    description:
      "Through rigirous scientific analysis like significance testing, Cronbach's alpha, correlation coefficients, and much more, we've found the actions that help everyone flourish faster, and guide our users towards them using tips throughout SHAPE.",
  },
  {
    heading: "How does SHAPE help with faster flourishing?",
    description:
      "SHAPE science has decoded the factors that correlate with flourishing directly and indirectly, developing the secret formula that can help individuals achieve flourishing faster by offering a targeted approach.",
  },
]

export { gotQuestionsData, gotQuestionsFlourishingData }
