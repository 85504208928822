import React from "react"
import "./flourishing-easy.scss"
import { FlourishDesktop } from "./components/flourishDesktop/flourishDesktop"
import { FlourishMobile } from "./components/flourishMobile/flourishMobile"

export const FlourishingEasy = () => {
  return (
    <>
      <FlourishMobile />
      <FlourishDesktop />
    </>
  )
}
