const getSettings = (right, left) => {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: right,
    prevArrow: left,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return settings
}

const carouselData = [
  {
    heading: "About Us",
    img: "about",
    desc: "Learn more about us and our mission to help everyone flourish faster.",
    link: "/about",
    cta: "See Our Experts",
  },
  {
    heading: "Partnership",
    img: "partnership",
    desc: "Power your service with SHAPE insights. We measure, you solve.",
    link: "/partner", //partner
    cta: "Let's Team Up",
  },

  {
    heading: "SHAPE Science",
    img: "science",
    desc: "Love science as much as we do? Dive deep into the science behind SHAPE.",
    link: "/science",
    cta: "See Our Science",
  },
]

export { getSettings, carouselData }
