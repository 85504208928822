import React from "react"
import "./pioneers.scss"
import { Link } from "gatsby"
import Partnership from "../../images/learnmore/banner-1.svg"
import About from "../../images/learnmore/banner-2.svg"
import Science from "../../images/learnmore/banner-3.svg"
import { carouselData, getSettings, settings } from "./helper"
import Slider from "react-slick"
import ArrowRight from "../../images/learnmore/arrowRight.svg"
import ArrowLeft from "../../images/learnmore/arrowLeft.svg"

export const Pioneers = () => {
  const CarouselImgMap = {
    partnership: Partnership,
    about: About,
    science: Science,
  }
  let settings = getSettings(
    <img src={ArrowRight} alt="next" style={{ right: "40px !important" }} />,
    <img src={ArrowLeft} alt="prev" style={{ left: "40px !important" }} />
  )
  return (
    <div className="pioneersWrapper">
      <h1>Pioneers of Flourishing</h1>
      <p>Get to know SHAPE.</p>
      <div className="pioneersCarouselWrapper">
        <Slider {...settings}>
          {carouselData?.map((card, index) => (
            <div
              className={`pioneersCarouselCard ${
                index === 2 ? "scienceCard" : ""
              } ${index === 1 ? "aboutCard" : ""}`}
              key={card.heading}
              data-index={index}
            >
              <div className="pioneersImg">
                <img src={CarouselImgMap[card.img]} alt={card.heading} />
              </div>
              <div className="pioneersContentItem">
                <h3>{card.heading}</h3>
                <p>{card.desc}</p>
                <Link to={card.link}>{card.cta}</Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
