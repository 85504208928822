import React, { useEffect, useRef, useState } from "react"
import "./trust-companies.scss"
import { Link } from "gatsby"
import Slider from "react-slick"
import { ClientFeedback } from "./components/clientFeedback/clientFeedback"
import Doug from "../../images/learnmore/doug2.png"
import Emma from "../../images/learnmore/emma.png"
import Jenefir from "../../images/learnmore/jennifer.png"
import Bob from "../../images/learnmore/bob.png"
import Nicola from "../../images/home/overcoming/advisor.jpg"
import ArrowRight from "../../images/learnmore/arrowRight.svg"
import ArrowLeft from "../../images/learnmore/arrowLeft.svg"
import Sodexo from "../../images/home/clientLogos/sodexo.svg"
import FourDayWeek from "../../images/home/clientLogos/fourDayWeek.svg"
import P from "../../images/home/clientLogos/p.svg"
import Efg from "../../images/home/clientLogos/efg.svg"
import PeckPeckAssociates from "../../images/home/clientLogos/peckPeckAssociates.svg"
import Smh from "../../images/home/clientLogos/smh.svg"
import Powerplant from "../../images/home/clientLogos/powerplant.svg"
import CnfFoods from "../../images/home/clientLogos/cnfFoods.svg"
import Aqai from "../../images/home/clientLogos/aqai.svg"
import AutismPartnership from "../../images/home/clientLogos/autismPartnership.svg"
import Ricoh from "../../images/home/clientLogos/ricoh.svg"
import RStrategy from "../../images/home/clientLogos/rStrategy.svg"
import Aquaconsultants from "../../images/home/clientLogos/aquaconsultants.svg"
import Seatle from "../../images/home/clientLogos/seatle.svg"
import BetterHR from "../../images/home/clientLogos/betterHR.svg"
import ExecutiveNetworks from "../../images/home/clientLogos/executiveNetworks.svg"
import Aea from "../../images/home/clientLogos/aea.svg"
export const TrustCompanies = () => {
  const sliderRef = useRef(null)
  const [isAnimating, setIsAnimating] = useState(true)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkIsMobile = () => {
      if (typeof window !== "undefined") {
        setIsMobile(window.matchMedia("(max-width: 786px)").matches)
      }
    }

    checkIsMobile()

    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkIsMobile)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", checkIsMobile)
      }
    }
  }, [])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <img src={ArrowRight} alt="Next" />,
    prevArrow: <img src={ArrowLeft} alt="Previous" />,
  }

  // let data = [
  //   {
  //     name: "Doug Lowrie",
  //     feedback:
  //       "“I want to see my personal SHAPE scores compared to my teams, where I’m contributing, and where I need to focus more energy.”",
  //     designation: "Global Workplace Director, Sodexo",
  //     clientImg: Doug,
  //   },
  //   {
  //     name: "Nicola Thompson",
  //     feedback:
  //       "“SHAPE is different…SHAPE helps you understand employee experience, and what they need to do to flourish.”",
  //     designation: "ex-CEO, MADE.com",
  //     clientImg: Nicola,
  //   },
  //   {
  //     name: "Jennifer Wakefield",
  //     feedback:
  //       "“SHAPE insights provided a starting point for meaningful conversations.”",
  //     designation: "Marketing Manager, EFG Furniture",
  //     clientImg: Jenefir,
  //   },
  //   {
  //     name: "Emma Wilson",
  //     feedback:
  //       "“SHAPE has been a game changer. It really helps in any business case. It's really the full package. It's fantastic.”",
  //     designation: "Social Impact Director, Pareto FM",
  //     clientImg: Emma,
  //   },
  //   {
  //     name: "Bob Stamegna",
  //     feedback:
  //       "“What you really want is for your staff members to be flourishing, SHAPE really provided the data that helped us get to that.”",
  //     designation: "Head of HR & Well-being, CN Foods",
  //     clientImg: Bob,
  //   },
  // ]

  const AssetsMap = [
    { logoImg: ExecutiveNetworks, clientName: "executiveNetworks" },
    { logoImg: Seatle, clientName: "seatle" },
    { logoImg: Sodexo, clientName: "sodexo" },
    { logoImg: FourDayWeek, clientName: "fourDayWeek" },
    { logoImg: AutismPartnership, clientName: "autismPartnership" },
    { logoImg: Efg, clientName: "efg" },
    { logoImg: P, clientName: "p" },
    { logoImg: PeckPeckAssociates, clientName: "peckPeckAssociates" },
    { logoImg: Powerplant, clientName: "powerplant" },
    { logoImg: CnfFoods, clientName: "cnfFoods" },
    { logoImg: Aqai, clientName: "aqai" },
    { logoImg: Ricoh, clientName: "ricoh" },
    { logoImg: RStrategy, clientName: "rStrategy" },
    { logoImg: Aquaconsultants, clientName: "aquaconsultants" },
    { logoImg: Smh, clientName: "smh" },
    { logoImg: BetterHR, clientName: "betterHR" },
    { logoImg: Aea, clientName: "aea" },
  ]

  // const AssetsMapMobile = [
  //   {
  //     logoImgOne: Seatle,
  //     clientNameOne: "seatle",
  //     logoImgTwo: Sodexo,
  //     logoImgThree: FourDayWeek,
  //     logoImgFour: AutismPartnership,
  //     logoImgFive: P,
  //   },
  //   {
  //     logoImgOne: Efg,
  //     logoImgTwo: PeckPeckAssociates,
  //     logoImgThree: Powerplant,
  //     logoImgFour: CnfFoods,
  //   },
  //   {
  //     logoImgOne: Ricoh,
  //     logoImgFour: Smh,
  //     logoImgTwo: RStrategy,
  //     logoImgThree: Powerplant,
  //     logoImgFive: Aquaconsultants,
  //   },
  //   {
  //     logoImgOne: BetterHR,
  //     logoImgTwo: Aea,
  //     clientNameTwo: "aea",
  //     logoImgFive: Aqai,
  //   },
  // ]

  const duplicatedAssetsMap = Array(10).fill(AssetsMap).flat() // Duplicate AssetsMap 10 times
  // const duplicateMobileAssets = Array(11).fill(AssetsMapMobile).flat()
  const assetsToShow = isMobile ? duplicatedAssetsMap : duplicatedAssetsMap

  return (
    <div className="trustCompaniesWrapper">
      <h1>Companies are Flourishing at Work</h1>
      <div
        className={`trustCompaniesCarousel ${
          isAnimating ? "animation" : "noAnimation"
        } ${!isMobile ? "desktopWrapper" : ""}`}
      >
        <Slider ref={sliderRef} {...settings}>
          {assetsToShow?.map((client) => (
            <ClientFeedback {...client} isMobile={isMobile} />
          ))}
        </Slider>
      </div>

      {/* <Link className="trustCompaniesCta" to="/learnmore">
        Hear Our Clients
      </Link> */}
    </div>
  )
}
