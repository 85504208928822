let dataMobile = [
  {
    heading: "Flourishing is a Super-Metric",
    desc: "<b>Flourishing and productivity go up and down together.</b>",
    cta: {
      content: "",
      link: "",
    },
    image: "Metric",
  },
  {
    heading: "Flourish Faster",
    desc: "Personalised,<br/> science-backed guidance on the actions that accelerate everyone's chances of <strong>flourishing.</strong>",
    cta: {
      content: "",
      link: "",
    },
    image: "Faster",
  },
  {
    heading: "Faster Means Coordinated Speed",
    desc: "Get aligned on important business goals so individual actions support <b>collective progress.</b>",
    cta: {
      content: "Solve Business Challenges",
      link: "/learnmore/#address-business-challenges",
    },
    image: "BusinessArrow",
  },
  {
    heading: "Address Your Flourishing Deficit",
    desc: "SHAPE helps you measure where and how much everyone is struggling, striving, or flourishing.<br/><strong><br/>Precision level insights into areas that matter most.</strong>",
    cta: {
      content: "Explore the Explorers",
      link: "/science/#explorer-view",
    },
    image: "Deficit",
  },
]

let dataDesktop = [
  {
    heading: "Flourishing Made Easy",
    subHeading:
      "<strong>Three things to help you accelerate to flourishing in one simple package.</strong>",
    desc: "<br/><ul><li><h4>For all employees</h4>Personalised guidance to accelerate flourishing.</li><li><h4>For employees & managers</h4>Year round nudges to build a flourishing mindset.</li><li><h4>For executives</h4>Insights to address real business issues.</li></ul>",
    cta: {
      content: "Pricing",
      link: "/pricing",
    },
    image: "SimplePackage",
    desktopOnly: true,
  },
  {
    heading: "Flourishing is a Super-Metric",
    desc: "<b>Flourishing and productivity go up and down together.</b>",
    cta: {
      content: "",
      link: "",
    },
    image: "Metric",
  },
  {
    heading: "Flourish Faster",
    desc: "Personalised,<br/> science-backed guidance on the actions that accelerate everyone's chances of <strong>flourishing.</strong>",
    cta: {
      content: "",
      link: "",
    },
    image: "Faster",
  },
  {
    heading: "Faster Means Coordinated Speed",
    desc: "Get aligned on important business goals so individual actions support <b>collective progress.</b>",
    cta: {
      content: "Solve Business Challenges",
      link: "/learnmore/#address-business-challenges",
    },
    image: "BusinessArrow",
  },
  {
    heading: "Address Your Flourishing Deficit",
    desc: "SHAPE helps you measure where and how much everyone is struggling, striving, or flourishing.<br/><strong><br/>Precision level insights into areas that matter most.</strong>",
    cta: {
      content: "Explore the Explorers",
      link: "/science/#explorer-view",
    },
    image: "Deficit",
  },
]
let getStartedData = [
  {
    heading:
      "<b>Three things to help you accelerate to flourishing in one simple package.</b>",
    desc: "Available on all platforms <br/><br/><br/>",
    cta: {
      content: "Pricing",
      link: "/pricing",
    },
    image: "SimplePackage",
  },
  {
    heading: "<b>Flourishing Made Easy</b>",
    descSubHeading: "For all employees",
    desc: "Personalised guidance to accelerate flourishing.",
    cta: {
      content: "Pricing",
      link: "/pricing",
    },
    image: "Mob3",
  },
  {
    heading: "<b>Flourishing Made Easy</b>",
    descSubHeading: "For employees & managers",
    desc: "Year round nudges to build a flourishing mindset",
    cta: {
      content: "Pricing",
      link: "/pricing",
    },
    image: "Mob2",
  },
  {
    heading: "<b>Flourishing Made Easy</b>",
    descSubHeading: "For executives",
    desc: "Insights to address real business issues.",
    cta: {
      content: "Pricing",
      link: "/pricing",
    },
    image: "Mob1",
  },
]

const getSettings = (right, left, setPageNo) => {
  let settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: right,
    prevArrow: left,
    beforeChange: (oldIndex, newIndex) => {
      setTimeout(() => {
        setPageNo && setPageNo(newIndex)
      }, 200)
    },
  }
  return settings
}
export { dataMobile, dataDesktop, getSettings, getStartedData }
