import React, { useEffect, useState } from "react"
import "../../flourishing-easy.scss"
import { Link } from "gatsby"
import Slider from "react-slick"
import SimplePackage from "../../../../images/home/flourishEasy/simplePackageMobile.svg"
import SimplePackagePng from "../../../../images/home/flourishEasy/simplePackageMobile.png"
import Mob1 from "../../../../images/home/flourishEasy/flourish-mob1-new.svg"
import Mob1Png from "../../../../images/home/flourishEasy/flourish-mob1-new.png"
import Mob2 from "../../../../images/home/flourishEasy/flourish-mob2-new.svg"
import Mob2Png from "../../../../images/home/flourishEasy/flourish-mob2-new.png"
import Mob3 from "../../../../images/home/flourishEasy/flourish-mob3-new.svg"
import Mob3Png from "../../../../images/home/flourishEasy/flourish-mob3-new.png"
import ArrowRight from "../../../../images/learnmore/arrowRight-light.svg"
import ArrowLeft from "../../../../images/learnmore/arrowLeft-light.svg"
import { isSafari } from "react-device-detect"

import { getStartedData, getSettings } from "../../help"

export const FlourishMobile = () => {
  const [isSafariBrow, setIsSafari] = useState(false)
  useEffect(() => {
    setIsSafari(isSafari)
  }, [isSafari])

  const AssetsMap = {
    SimplePackage: isSafariBrow ? SimplePackagePng : SimplePackage,
    Mob1: isSafariBrow ? Mob1Png : Mob1,
    Mob2: isSafariBrow ? Mob2Png : Mob2,
    Mob3: isSafariBrow ? Mob3Png : Mob3,
  }
  let settings = getSettings(
    <img src={ArrowRight} alt="next" style={{ right: "40px !important" }} />,
    <img src={ArrowLeft} alt="prev" style={{ left: "40px !important" }} />,
    null
  )

  return (
    <div className="flourishEasyWrapper flourishMobile">
      <h1>Get Started</h1>
      <div className="flourishEasyCarousel">
        <Slider {...settings}>
          {getStartedData.map((card, index) => (
            <div className="flourishEasyCard">
              <p
                className="flourishDesc flourishTopDesc"
                dangerouslySetInnerHTML={{ __html: card.heading }}
              />
              <div
                className={`flourishEasyCardImgMobile ${
                  index === 0 ? "flourishEasyCardImgMobileSimplePackage" : ""
                }`}
              >
                <img src={AssetsMap[card.image]} alt={card.heading} />
              </div>
              <div
                className={`flourishEasyCardContent ${
                  index === 0 ? "flourishDescCentered" : ""
                }`}
              >
                {card?.descSubHeading && (
                  <h4 className="flourishDescSubHeading">
                    {card.descSubHeading}
                  </h4>
                )}
                <p
                  className={`flourishDesc`}
                  dangerouslySetInnerHTML={{ __html: card.desc }}
                />
                {card.cta.content && (
                  <Link to={card.cta.link} className="flourishMobileCta">
                    {card.cta.content}
                  </Link>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
