import React from "react"
import "../../trust-companies.scss"

export const ClientFeedback = (props) => {
  // let { clientImg, feedback, name, designation } = props
  let { logoImg, clientName, isMobile } = props

  const isSeatleLogo = clientName === "seatle"

  return (
    <div className="clientFeedbackWrapper">
      {/* <div
        className={`clientImg ${name.includes("Jennifer") ? "jen" : ""} ${
          name.includes("Emma") ? "emma" : ""
        } ${name.includes("Bob") ? "bob" : ""}`}
      >
        <img src={clientImg} alt="Client" lazy="true" />
      </div>
      <div className="clientFeedbackContent">
        <p className="clientFeedback">{feedback}</p>
        <div>
          <h4 className="clientName">{name}</h4>
          <p className="clientDesignation">{designation}</p>
        </div>
      </div> */}

      {!isMobile || true ? (
        <div className="clientLogoCont clientLogoContMobile ">
          <span className="flourishText">Flourishing at</span>
          <div
            className={`imgCont ${props.clientName ? props.clientName : ""}`}
          >
            <img src={logoImg} alt="company logo" />
          </div>
        </div>
      ) : (
        <div className="clientLogoContMobile">
          <div className="logoCont">
            <div className={`flourishText ${isSeatleLogo ? "seatleLogo" : ""}`}>
              Flourishing at
            </div>
            <div
              className={`imgContMobile ${
                props.clientNameOne === "seatle" ? "seatle" : ""
              }`}
            >
              <img src={props.logoImgOne} alt="clientLogo" />
            </div>
          </div>
          <div className="logoCont">
            <div className={`flourishText ${isSeatleLogo ? "seatleLogo" : ""}`}>
              Flourishing at
            </div>
            <div
              className={`imgContMobile ${
                props.clientNameTwo === "aea" ? "aea" : ""
              }`}
            >
              <img src={props.logoImgTwo} alt="clientLogo" />
            </div>
          </div>
          {!!props.logoImgThree && (
            <div className="logoCont">
              <div className={`flourishText`}>Flourishing at</div>
              <div className="imgContMobile">
                <img src={props.logoImgThree} alt="clientLogo" />
              </div>
            </div>
          )}
          {!!props.logoImgFour && (
            <div className="logoCont">
              <div className={`flourishText`}>Flourishing at</div>
              <div className="imgContMobile">
                <img src={props.logoImgFour} alt="clientLogo" />
              </div>
            </div>
          )}
          {!!props.logoImgFive && (
            <div className="logoCont">
              <div className={`flourishText`}>Flourishing at</div>
              <div className="imgContMobile">
                <img src={props.logoImgFive} alt="clientLogo" />
              </div>
            </div>
          )}{" "}
        </div>
      )}
    </div>
  )
}
